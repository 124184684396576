@import 'main.scss';
.sectionContainer {
  padding-top: 200px;
  padding-bottom: 25px;
  background-color: var(--primary);

  @media screen and (min-width: 1000px) {
    padding-top: 400px;
    padding-left: 0;
    padding-right: 0;
  }

  .imagesContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 300px;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;

    @media screen and (min-width: 1000px) {
      flex-direction: row;
      column-gap: 40px;
      width: calc(150% - 64px);
      max-width: unset;
      margin-left: 50%;
      transform: translateX(-50%);
      justify-content: center;
      align-items: center;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      width: 292px;
      height: 208px;
      overflow: hidden;
      border-radius: 5px;

      @media screen and (min-width: 1000px) {
        width: 476px;
        height: 338px;
        border-radius: 10px;
      }

      @media screen and (min-width: 1200px) {
        width: 548px;
        height: 390px;
      }

      @media screen and (min-width: 1600px) {
        width: 695px;
        height: 495px;
        border-radius: 15px;
      }

      picture {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          transition: all 300ms ease-in-out;
          &:hover {
            transform: scale(1.07);
          }
        }
      }
    }
  }
}
