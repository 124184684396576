@import 'main.scss';
.mainFooter {
  font-family: var(--font-avant-regular);
  height: 100vh;
  width: 100vw;
  background: var(--primary);
  position: relative;

  .titleContainer {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 99;
    user-select: none;
    pointer-events: none;
    gap: 8px;

    p {
      font-family: var(--font-sora);
      margin-top: 40px;
      color: var(--background);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px;
      max-width: 300px;

      @media screen and (min-width: 1000px) {
        max-width: unset;
      }
    }

    .heroTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 360px;

      @media screen and (min-width: 1000px) {
        max-width: 1100px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 1300px;
      }

      h2 {
        color: var(--white);
        text-align: center;

        :last-child {
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: var(--white, #fff);
          color: transparent;

          @media screen and (min-width: 1000px) {
            -webkit-text-stroke-width: 3px;
            margin-top: 16px;
          }
        }
      }
    }
  }
}

.buttonContainer {
  margin-top: 40px;
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: center;
}
