@import 'main.scss';
.heroWrap {
  padding-top: 130px;

  @media screen and (min-width: 1000px) {
    padding-top: 114px;
  }

  .heroLogo {
    width: auto;
    height: 57px;
    margin-bottom: 32px;

    @media screen and (min-width: 1000px) {
      height: 100px;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .heroContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 100px;

    @media screen and (min-width: 1000px) {
      margin-bottom: 0;
    }

    .title {
      display: flex;
      flex-wrap: wrap;
      max-width: 350px;

      @media screen and (min-width: 1000px) {
        max-width: 900px;
      }

      @media screen and (min-width: 1200px) {
        max-width: unset;
      }
    }
    .span {
      color: var(--Primary, #17082f);
      -webkit-text-stroke-width: 2px;
      @media screen and (min-width: 1000px) {
        letter-spacing: 0;
        -webkit-text-stroke-width: 4px;
      }
    }

    p {
      font-family: var(--font-sora);
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      max-width: 500px;

      @media screen and (min-width: 1000px) {
        font-size: 20px;
        line-height: 28px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 690px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 900px;
      }
    }

    .heroBtn {
      background-color: transparent;
      border: 1px solid var(--Primary, #17082f);
      border-radius: 500px;
      padding: 16px 40px;
      color: var(--Primary, #17082f);
      text-transform: uppercase;
      font-family: var(--font-avant);
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.05em;
      text-align: center;
    }
  }
  .heroImage {
    width: 100%;
    max-width: 414px;
    padding: 130px 0;
    margin: 0 auto;
    img {
      border-radius: 20px;
    }

    @media screen and (min-width: 1000px) {
      max-width: unset;
    }
  }
}
