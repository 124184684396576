@import 'main.scss';
.sectionPassionate {
  height: auto;
  width: 100%;
  margin: 100px 0;
  overflow: hidden;

  @media screen and (min-width: 1000px) {
    min-height: 100dvh;
    margin: 200px 0px;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    padding-top: 80px;

    @media screen and (min-width: 1000px) {
      padding-top: 150px;
    }

    h2 {
      display: flex;
      color: var(--primary);
      flex-direction: column;
      align-items: center;
      margin: auto;
      span:first-of-type {
        padding: 0 6px;
      }
    }
  }

  .jobList {
    position: relative;
    z-index: 5;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;

    @media screen and (min-width: 1000px) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 32px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 1082px;
      margin: 150px auto 200px auto;
    }

    @media screen and (min-width: 1600px) {
      max-width: 100%;
    }

    .jobItem {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 24px;
      border-radius: 10px;
      background: white;
      font-family: var(--font-sora);
      max-width: 400px;
      min-height: 220px;

      @media screen and (min-width: 1600px) {
        margin: 0;
      }

      h3 {
        font-size: 26px;
        line-height: 31px;
        color: var(--secondary);
        font-weight: 700;

        @media screen and (min-width: 1000px) {
          font-size: 32px;
          line-height: 33px;
        }
      }

      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;

        @media screen and (min-width: 1000px) {
          font-size: 18px;
          line-height: 26px;
        }
      }

      .jobTags {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: auto;

        @media screen and (min-width: 1000px) {
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          gap: 8px;
        }

        small {
          display: none;
          padding-left: 8px;
          color: var(--Primary-300, #4c2882);
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          @media screen and (min-width: 1000px) {
            display: block;
          }
        }

        span {
          border-bottom: 1px solid #e0e0e0;
          color: var(--Primary-300, #4c2882);
          padding: 10px 12px;
          font-size: 14px;
          line-height: 16px;

          @media screen and (min-width: 1000px) {
            border: none;
            display: flex;
            align-items: center;
            font-family: var(--font-sora);
            font-size: 15px;
            line-height: 16px;
            color: var(--primary);
            font-weight: 700;
            text-transform: uppercase;
            height: auto;
            padding: 0;
          }
        }

        span:last-of-type {
          border-bottom: none;
          align-items: center;
        }
      }
    }
  }
  .btnPostulation {
    @media screen and (min-width: 1000px) {
      display: none;
    }
  }
}

.spacing {
  height: 400px;
}
