@import 'main.scss';
.chipContainer {
  display: flex;
  align-items: center;
  padding: 16px 9px 12px 9px;
  gap: 10px;
  border-radius: 500px;
  background: transparent;
  border: 1px solid var(--white);
  z-index: 99;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  height: 80px;
  animation: expandAndContract 0.6s infinite alternate
    cubic-bezier(0.25, 0.46, 0.45, 0.94);

  .arrow {
    animation: expandedArrow 0.6s infinite alternate
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
    padding-right: 2px;
    width: 19px;

    path {
      stroke: var(--white);
      stroke-width: 2px;
    }
  }
}

@keyframes expandAndContract {
  0% {
    height: 80px;
    bottom: 60px;
  }

  100% {
    height: 100px;
    bottom: 40px;
  }
}

@keyframes expandedArrow {
  0% {
    transform: scaleY(1);
    height: 50px;
  }

  100% {
    transform: scaleY(1.4);
    height: 70px;
  }
}
