@import 'main.scss';
.sectionWrap {
  font-family: var(--font-avant);
  color: var(--primary);
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 1000px) {
    max-width: 560px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 700px;
  }

  span {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 1.6px;
    color: var(--secondary);
    text-transform: uppercase;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.64px;

    @media screen and (min-width: 1000px) {
      font-size: 45px;
      line-height: 50px;
    }
  }

  p {
    font-family: var(--font-sora);
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    max-width: 500px;

    @media screen and (min-width: 1000px) {
      font-size: 20px;
      line-height: 28px;
      max-width: unset;
    }
  }
}
