@import 'main.scss';
.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-bottom: 100px;
  padding-top: 100px;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 170px;
    padding-top: 170px;
    align-items: center;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
    max-width: 500px;

    @media screen and (min-width: 1000px) {
      flex-direction: row;
      max-width: 792px;
    }

    @media screen and (min-width: 1200px) {
      flex-direction: column;
      width: 380px;
    }

    @media screen and (min-width: 1600px) {
      width: 490px;
    }
  }

  svg {
    position: absolute;
    top: -30px;
    left: 50%;
    width: 75px;
    height: 53px;
    @media screen and (min-width: 1000px) {
      left: 30%;
      width: 91px;
      height: 75px;
    }
    @media screen and (min-width: 1200px) {
      left: 60%;
      width: 106px;
    }

    @media screen and (min-width: 1600px) {
      width: 137px;
    }
  }

  picture {
    width: 352px;
    height: 375px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1000px) {
      width: 380px;
      height: 404px;
    }

    @media screen and (min-width: 1200px) {
      width: 400px;
      height: 426px;
    }

    @media screen and (min-width: 1600px) {
      width: 560px;
      height: 596px;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 15px;
    }
  }

  .card {
    width: 100%;
    background-color: var(--white);
    font-family: var(--font-sora);
    color: var(--primary);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    border-radius: 20px;

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 23px;
      display: flex;
      flex-direction: column;
      color: var(--Primary-300, #4c2882);
      @media screen and (min-width: 1000px) {
        font-size: 26px;
        line-height: 33px;
      }
      .number {
        color: var(--secondary);
        font-size: 30px;
        line-height: 40px;
        @media screen and (min-width: 1000px) {
          font-size: 45px;
          line-height: 50px;
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
    }
  }
}

.direction {
  @media screen and (min-width: 1200px) {
    flex-direction: row-reverse;
  }
}
