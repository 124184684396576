@import 'main.scss';
.footerContainer {
  font-family: var(--font-sora);
  margin: auto;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  z-index: 0;

  .footerOne {
    min-height: 120vh;
    background-color: var(--background);

    @media screen and (min-width: 1000px) {
      height: 250vh;
    }
  }

  .footerTwo {
    background-color: var(--background);
  }
}
