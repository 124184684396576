@import 'main.scss';
.whyChooseUsWrap {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 100px;

  @media screen and (min-width: 1000px) {
    padding-top: 150px;
  }

  h2 {
    display: flex;
    flex-direction: column;
    color: var(--primary);

    :first-child {
      padding-top: 14px;
      padding-right: 6px;
    }
  }

  .description {
    font-family: var(--font-sora);
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-top: 32px;
    max-width: 500px;

    @media screen and (min-width: 1000px) {
      font-size: 18px;
      line-height: 26px;
      margin-top: 40px;
      max-width: 729px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 20px;
      line-height: 28px;
      margin-top: 40px;
      max-width: 950px;
    }

    @media screen and (min-width: 1600px) {
      max-width: 1100px;
    }
  }

  .buttonContainer {
    position: absolute;
    right: 10vw;
    bottom: -200px;
  }

  .wave {
    height: 30vh;
    margin-top: 50px;

    @media screen and (min-width: 1600px) {
      margin-top: 150px;
    }
  }
}
