@import 'main.scss';
.sectionContainer {
  overflow: hidden;
  margin: 100px 0px;
}
.sectionWrap {
  @media screen and (min-width: 1600px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
    gap: 50px;
  }

  .title {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: 100%;

    @media screen and (min-width: 1200px) {
      max-width: 950px;
    }

    h2 {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;
      @media screen and (min-width: 1000px) {
        gap: 8px;
        padding-bottom: 50px;
      }
      span {
        padding-top: 1px;
        @media screen and (min-width: 1000px) {
          padding-top: 14px;
        }
      }
    }
  }

  .description {
    font-family: var(--font-sora);
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    color: var(--primary);
    max-width: 500px;

    @media screen and (min-width: 1000px) {
      font-size: 20px;
      line-height: 28px;
      max-width: unset;
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 60px;

    @media screen and (min-width: 1200px) {
      flex-direction: row;
    }

    @media screen and (min-width: 1600px) {
      flex-direction: column;
      margin-top: 0;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    background-color: var(--white);
    border-radius: 20px;
    font-family: var(--font-sora);
    color: var(--primary);
    width: 100%;
    max-width: 500px;
    @media screen and (min-width: 1200px) {
      width: 490px;
    }

    @media screen and (min-width: 1600px) {
      width: 450px;
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      display: flex;
      flex-direction: column;
      .number {
        color: var(--secondary);
        font-size: 35px;
        line-height: 50px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;

      @media screen and (min-width: 1000px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .user {
      display: flex;
      align-items: center;
      gap: 11px;

      picture {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .userData {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .userName {
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        color: #222;
      }

      .userRole {
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
        color: #6b6b6b;
      }
    }
  }
}
