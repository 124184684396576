@import 'main.scss';
.circularButtonContainer {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;

  @media screen and (min-width: 1025px) {
    width: 150px;
    height: 150px;
  }

  @media screen and (min-width: 1201px) {
    width: 170px;
    height: 170px;
  }

  .message {
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;

    svg {
      width: 50px;
      height: auto;

      @media screen and (min-width: 1025px) {
        width: 62px;
      }

      @media screen and (min-width: 1201px) {
        width: 75px;
      }
    }
  }

  .button {
    position: relative;
    color: #000;
    cursor: pointer;

    svg {
      width: 120px;
      height: 120px;
      fill: currentColor;
      transform-origin: center;
      top: 2px;
      position: relative;
      -webkit-animation-name: circleRotation;
      -moz-animation-name: circleRotation;
      -ms-animation-name: circleRotation;
      -o-animation-name: circleRotation;
      animation-name: circleRotation;
      -webkit-animation-duration: 20s;
      -moz-animation-duration: 20s;
      -ms-animation-duration: 20s;
      -o-animation-duration: 20s;
      animation-duration: 20s;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
      -o-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
      -ms-animation-timing-function: linear;
      -o-animation-timing-function: linear;
      animation-timing-function: linear;

      @media screen and (min-width: 1025px) {
        width: 145px;
        height: 145px;
      }

      @media screen and (min-width: 1201px) {
        width: 155px;
        height: 155px;
      }

      text {
        font-family: var(--font-avant);
      }
    }
  }
}

@keyframes circleRotation {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}
