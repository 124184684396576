@import 'main.scss';
.sectionContainer {
  margin: 100px 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (min-width: 1000px) {
    margin: 200px 0px 0px 0px;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media screen and (min-width: 1000px) {
      gap: 80px;
    }

    @media screen and (min-width: 1200px) {
      flex-direction: row;
    }

    h2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: var(--primary);
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .subTitle {
        font-family: var(--font-avant);
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 1.6px;
        color: var(--secondary, #af2eff);
        text-transform: uppercase;
        font-weight: bold;
      }

      p {
        font-family: var(--font-sora);
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        color: var(--primary, #17082f);
        max-width: 500px;

        @media screen and (min-width: 1000px) {
          font-size: 20px;
          line-height: 28px;
          max-width: 800px;
        }
      }

      .logo {
        height: 57px;
        max-width: 150px;

        @media screen and (min-width: 1000px) {
          height: 76px;
          max-width: 200px;
        }
      }

      .client,
      .tags,
      .technologies,
      .features {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      ul {
        display: flex;

        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        margin-bottom: 24px;

        li {
          svg,
          img {
            width: 48px;
            height: 48px;
          }

          span {
            font-family: var(--font-sora);
            font-size: 16px;
            font-weight: 300;
            line-height: 22px;
            color: var(--primary, #17082f);

            @media screen and (min-width: 1000px) {
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
      }

      .tags {
        ul {
          flex-direction: column;
          flex-wrap: nowrap;
          gap: 4px;
          .tag {
            display: grid;
            grid-template-columns: 48px 1fr;
            gap: 8px;
            align-items: center;

            .tagIcon {
              width: 48px;
              height: 48px;
            }
          }
        }
      }

      .technologies {
        ul {
          flex-direction: row;
          flex-wrap: wrap;
          gap: 24px;

          @media screen and (min-width: 1000px) {
            gap: 32px;
          }

          .tech {
            display: flex;
            align-items: center;
            flex-direction: column;
            max-width: 70px;
            width: 100%;

            picture {
              width: auto;
              img {
                width: 42px;
                height: 42px;
              }
            }
            span {
              text-align: center;
            }
          }
        }
      }

      .features {
        ul {
          display: flex;
          list-style: none;
          gap: 12px;
          align-items: center;

          .feature {
            font-family: var(--font-sora);
            span {
              font-size: 15px;
              line-height: 16px;
              color: var(--primary);
              font-weight: 700;
              text-transform: uppercase;
              height: 100%;
            }

            small {
              padding-left: 8px;
              color: var(--Primary-300, #4c2882);
              font-size: 20px;
              font-weight: 400;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}
