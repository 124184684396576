@import 'main.scss';
.sectionContainer {
  width: 100vw;
  height: max-content;

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: column;

  @media screen and (min-width: 1601px) {
    flex-direction: row;
  }

  position: relative;
  overflow: hidden;

  .textContainer {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-self: flex-start;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 340px;

    @media screen and (min-width: 1000px) {
      max-width: 800px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 950px;
    }

    @media screen and (min-width: 1601px) {
      max-width: 821px;
    }

    @media screen and (min-width: 1901px) {
      max-width: 950px;
    }

    h2 {
      display: flex;
      flex-direction: column;

      span,
      p {
        width: fit-content;
      }
    }

    .description {
      width: 100%;
      font-family: var(--font-sora);
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      color: var(--primary);

      @media screen and (min-width: 1000px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .canvasContainer {
    padding-top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 271px;
    height: 280px;

    @media screen and (min-width: 1000px) {
      width: 350px;
      height: 362px;
    }

    @media screen and (min-width: 1200px) {
      width: 380px;
      height: 393.21px;
    }

    @media screen and (min-width: 1600px) {
      padding-top: 190px;
      width: 400px;
      height: 413.91px;
    }

    @media screen and (min-width: 1900px) {
      padding-top: 190px;
      width: 520px;
      height: 537.64px;
    }
  }
}
