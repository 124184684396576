@import 'main.scss';
.wordsContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transform: rotate(-5deg);
  margin: 50px 0px;
  width: 101%;
  margin-left: -1%;
  position: relative;
  &.reduceMargin {
    margin: 100px 0px;
  }
  @media screen and (min-width: 1000px) {
    margin: 150px 0px;
  }

  .banner {
    display: flex;
    align-items: center;
    gap: 32px;
    transform: translateX(0);
    width: 100%;

    .word {
      font-family: var(--font-avant);
      font-size: 65px;
      font-style: normal;
      font-weight: 700;
      line-height: 70px;
      text-transform: uppercase;
      -webkit-text-stroke: 3px #d8cce6;
      paint-order: stroke fill;
      color: var(--background);

      @media screen and (min-width: 1000px) {
        font-size: 120px;
        line-height: 120px;
        -webkit-text-stroke-width: 5px;
      }

      @media screen and (min-width: 1200px) {
        -webkit-text-stroke-width: 6px;
        font-size: 150px;
        line-height: 150px;
      }

      @media screen and (min-width: 1600px) {
        font-size: 195px;
        line-height: 175px;
      }

      &.word1,
      &.word3 {
        color: #d8cce6;
      }
    }
  }

  .bannerTwo {
    width: 100%;
    transform: translateX(-400px);
  }
}
